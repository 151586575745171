$font_family: 'Roboto', sans-serif;

$background_color: #fcfcfc;
$background_color_left_menu: #fff;
$color_blue_step0: #262e44;
$color_blue_step0_darker: #eeeeee;
$color_blue_step1: #6a8397;
$color_blue_step2: #fcfcfc; // unused

$color_red_err: #b53929;
$color_red_err2: rgba(181, 57, 41, 0.07);
$color_green_err : #4a8032;

$border-color-gray: #eee;
$border-color-gray2: #dfdfdf;
$border-color-blue_step0: #2c4355;
$border-color-blue_step4: #5987aa;
$border-color-redlike: #c7152d;

//General settings//

$menu_top_height: 220px;
$menu_left_padding_left:25px;
$menu_left_width: 220px;
$menu_left_hide_width: 220px;
$menu_right_width:200px;

$main_elements_bottom_padding: 15px;
$main_elements_bottom_margin: 15px;

//Fonts main colors
$font_color_gray1: #313131;
$font_color_gray2: #484848;
$font_color_light_gray: rgba(72, 72, 72, 0.58);

// mixins
@mixin menu_hide_anim_time {
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
  transition: ease 0.5s;
}