.popup_box {
  position:absolute;
  top:200px;
  left:200px;
  background-color:#fff;
  z-index:30;
  width:300px;
  height:200px;

  border:10px solid $color_blue_step0;
  border-top:33px solid $color_blue_step0;

  -webkit-box-shadow: 0px 0px 39px 0px rgba(0,0,0,0.44);
  -moz-box-shadow: 0px 0px 39px 0px rgba(0,0,0,0.44);
  box-shadow: 0px 0px 39px 0px rgba(0,0,0,0.44);

  .popup_header {
    background-color:$color_blue_step0;
    padding:8px 0;
    position:relative;
    bottom:32px;
    cursor: all-scroll;

    .title {
      color:#fff;
      height:16px;
      font-weight: 300;
      font-size: 14px;
    }

    .close_popup {
      position:absolute;
      right:0;
      top:7px;
      color:#fff;
      font-size:13px;
      cursor: pointer;

      span {
        color:#fff;
      }
    }
  }

  .popup {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;

    iframe {
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
    }
  }
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;

  &.ui-resizable-e {
    cursor: e-resize;
    width: 7px;
    right: -5px;
    top: 0;
    height: 100%;
  }

  &.ui-resizable-s {
    cursor: s-resize;
    height: 7px;
    width: 100%;
    bottom: -5px;
    left: 0;
  }

  &.ui-resizable-se {
    cursor: se-resize;
    width: 12px;
    height: 12px;
    right: -12px;
    bottom: -11px;

    &.ui-icon {
      width: 16px;
      height: 16px;

      &.ui-icon-gripsmall-diagonal-se {
        background: url('/panel_files/img/resizable_white.png') center center no-repeat;
      }
    }
  }
}